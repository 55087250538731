// @import './styles/main.scss';
@import './styles/projects.scss';
@import './styles/navBar.scss';



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // width: 100%;
}

li{
    cursor: pointer;
}

body, html{
    height: 100%;
}

body{
    font-family: 'Carme';
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 2px;
    font-size: 1.1rem;
    text-align: left;
    background-color: #9494A6;
    display: flex;
    // grid-template-rows: 1fr;
    // grid-gap: 1.5em;
    overflow-x: hidden;
    // clip-path: polygon(100% 0, 0 0, 0 88%, 0% 100%);
}


#header-wrapper{
    grid-row: 1/2;
    grid-column: 1/4;
}

.header{
    display: flex;
    flex-direction: column;
    background-color: #1B1F26;
    padding: 3em 2em;
    width: 100%;
    align-items:center
}


// #footer-wrapper{
//     grid-row: 2/3;
//     grid-column: 1/4;


//     .footer{
//         color: #F2C48D;
//         background-color: #1B1F26;
//         // position: absolute;
//         bottom: 0;
//         width: 100%;
//     }
// }


h3{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 3000;
}

h4{
    margin-bottom: .7rem;
}

h3::after{
    content: ' ';
    display: block;
    width: 100%;
    height: 8px;
    border-bottom: 4px solid #F2C48D;
}