
$colors:(
    redd: #D90B1C,
    reddish: #59454C,
    drkgrey:#1B1F26,
    blacc: #0D0D0D,
    sands: rgb(245, 206, 159),
    reggrey:#9494A6,
    litegrey: #636373
);

    .headerTitle{
        color: red;
        width: 100%;
        text-align: center;
    }

    .headerTitle h1::after{
        content: '';
        display: block;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
        height: 8px;
        border-bottom: 5px solid #F2C48D;
        padding-top: 1.2rem;
        }

    // .header-content{
    //     margin-right: -15px;
    //     margin-left:  -15px;
    // }

    .navBar{
            background-color: white;
            height: 3.5em;
            align-items: center;
            margin-top: auto;
            margin-bottom: 50px;
            // justify-content: center;
    }

    .bio{
        margin: 1.3rem;
        color: white;
    }

    .bio p{
        padding: 2em 3em;
    }

    .nav-links{
        display: flex;
        cursor: pointer;
        height: 100%;
        color: map-get($colors, sands);
        font-weight: 700;
        justify-content: space-evenly;
        align-items: center;
    }