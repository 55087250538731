
$medium: 500px;

$medSkills: 768px;

@mixin ed {
    
}

@mixin medScrn{
    @media (min-width: #{$medium}){
        .sideSkills{
            margin-bottom: 1rem;
        }

        .sideSkills h3{
            height: auto;
            text-align: left;
            margin: 0;
        }  

        h3::after{
            min-width: 30rem
        }


        .contact-wrapper{
            // margin-top: 1rem;
            width: 100%;
        }

        .combine{
            // padding: 3rem 1.25rem;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        } 

        .icon{
        font-size: 30px;
        margin-left: 0 1rem;
        }
        

        .skillContainer{
            align-items: center;
        }

        // .deets{
        //     padding-left: 0;
        // }

        .sideSkills h3{
           margin-bottom: 1rem;
        }

    }
}

@mixin skillsMed{
    @media (min-width: #{$medium}){
        flex-direction: column;
        margin-top: 1rem;
        flex-wrap: nowrap;
        height: 30em;
        background-color: grey;

        li{
            margin-bottom: 1rem;
        }
    }
}

#contentWrapper{
    width: 80%;
    padding: auto 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

#projectsContainer{
    // padding: 20px 50px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    overflow: hidden;


.projects h1{
        text-align: center;
        font-size: 2em;
    }

.projects h1::after{
    content: '';
    display: block;
    width: 100%;
    height: 8px;
    border-bottom: 5px solid #F2C48D;
    padding-top: 1.2rem;
    }

.projectsList {
        display: grid;
        padding-top: 5rem;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));;
        justify-content: space-evenly;
        grid-gap: 2rem;
    }

    .projectsList img{
        cursor: pointer;
        transform: scale(1);
        transition: .3s ease-in-out;
    }

    .projectsList img:hover{
        transform: scale(1.02);
    }

    .skylines,
    .juxxed,
    .gitSearch,
    .contacter,
    .showRoom{
        width: 100%;
    }

    .sideSkills {
        padding-bottom: 2rem;
    }


    .combine{
        @include medScrn;
    }

    .mail-contact{
        display: flex;
        align-items: center;
        font-size: .92rem;
        margin-top: 1.5rem;
        margin-right: 1.5rem;
    }

    .skillContainer{
        margin: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        font-weight: 550;
        align-items: center;
        flex-direction: row;
        position: relative;
    }

    .skillContainer li{
        padding: 2px;
    }

    .sideSkills{
        margin: 3.5rem 0;
    }

    .contact-wrapper{
        margin-bottom: 5rem;
        margin-top: 2rem;
    }
     


    .contact-wrapper h3,
    .sideSkills h3{
        margin-bottom:  1em;
        text-align: left;
        font-size: 1.7rem;
    }

    .deets{
        color: white;
        // font-size: 1rem;
        font-weight: 600;
        display: inline-block;
        padding-left: 1em;
    }

    .skillsMiniwrap{
        font-size: .9rem;
        margin-top: 1rem;
        font-weight: 600;
        color: black;
    }

    .skillsMiniwrap a{
        text-decoration: none;
        color: black;
    }

    .repo{
        padding-top: .7rem;
    }

    .skillsMiniwrap a:hover{
        color: goldenrod;
    }

    .icon{
        font-size: 1.5rem;
        color: palegoldenrod;
        // margin-left: 1rem;
        // display: inline;
    }
    }



